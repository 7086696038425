<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <div id="buttongroup" style="display: flex; justify-content: flex-end">
            <div class="field is-grouped">
              <div class="control">
                <button :disabled="!$hasPermissions(clientSession, ['REPORT_TYPES'], 2) || isSaveDisabled" @click="handleStatesSubmit" type="submit" class="button is-accent has-text-white">Save</button>
              </div>
              <div class="control">
                <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="block">
                <form @submit.prevent="handleStatesSubmit()">
                  <div>

                    <div class="field">
                      <label class="label is-size-7">
                        State Code
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input
                          :class="{ 'input': true, 'is-danger': this.errors.code }"
                          type="text"
                          name="typecode"
                          v-model="input.StateAbbreviation"
                          :disabled="!isCreateMode"
                        />
                      </div>
                      <small style="color: #eaae5e!important;">State Code cannot be modified after saving the state configuration.</small>
                    </div>

                    <div class="field">
                      <label class="label is-size-7">
                        State Name
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input
                        :class="{ 'input': true, 'is-danger': this.errors.name }" type="text" name="name" v-model="input.StateName" />
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
            <div class="column">
              <!-- Reserved for future -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
let pageName = 'State Configuration'

export default {
  components: {
    Loading
  },
  props: {
    model: {
      default: () => {
        return {
          code: '',
          name: '',
          category: ''
        }
      }
    }
  },
  data () {
    return {
      input: {
        StateAbbreviation: '',
        StateName: ''
        // category: ''
      },
      errors: {},
      inputDefault: {},
      isLoading: false,
      categories: [],
      pageheading: pageName
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let code = this.$route.params.id
      return !code
    },
    inputIsValid () {
      let isValid = true

      if (
        !this.input.StateAbbreviation ||
        this.input.StateAbbreviation.length > 5 ||
        this.input.StateAbbreviation.length < 2
      ) {
        isValid = false
      }

      if (
        !this.input.stateName ||
        this.input.stateName.length > 50 ||
        this.input.stateName.length < 2
      ) {
        isValid = false
      }

      this.triggerErrorCodes()

      return isValid
    },
    isSaveDisabled () {
      let shouldDisable = false

      if (!this.inputIsValid) {
        shouldDisable = true
      }

      return this.isLoading || shouldDisable
    }
  },
  mounted () {
    // if external direct link, need to load the model state
    if (!this.isCreateMode) {
      if (this.$route.params.model) {
        this.input = JSON.parse(this.$route.params.model)
        this.input = {
          ...this.input,
          StateAbbreviation: this.input.stateAbbreviation,
          StateName: this.input.stateName
        }
      } else {
        this.getState()
      }
    }
  },
  methods: {
    async getState () {
      this.isLoading = true
      this.api().states.get(this.$route.params.id, ({ error, response }) => {
        if (error) {
          this.errorToast(error)
        } else {
          this.input = response
          this.input = {
            ...this.input,
            StateAbbreviation: this.input.stateAbbreviation,
            StateName: this.input.stateName
          }
        }
        this.isLoading = false
      })
    },
    triggerErrorCodes () {
      if (this.input.StateAbbreviation && this.input.StateAbbreviation.length > 5) {
        this.errors.code = 'State Code cannot be more than 5 characters'
      } else if (this.input.StateAbbreviation && this.input.StateAbbreviation.length < 2) {
        this.errors.code = 'State Code cannot be less than 2 characters'
      } else {
        delete this.errors.code
      }

      if (this.input.StateName && this.input.StateName.length > 50) {
        this.errors.name = 'State Name cannot be more than 50 characters'
      } else if (this.input.StateName && this.input.StateName.length < 2) {
        this.errors.name = 'State Name cannot be less than 2 characters'
      } else {
        delete this.errors.name
      }
    },
    async handleStatesSubmit () {
      let isEditing = this.$route.params.id !== undefined

      if (this.input.StateAbbreviation === '') {
        this.errors.code = 'State Code is required'
      } else {
        delete this.errors.code
      }

      if (!this.input.StateName) {
        this.errors.name = 'State Name is required'
      } else {
        delete this.errors.name
      }

      if (Object.keys(this.errors).length > 0) {
        this.$forceUpdate()
        this.errorToast('The form contains errors. Please correct them and try again.')
        return
      }

      if (isEditing) {
        // Update existing State
        this.api().states.update(this.$route.params.id, this.input, ({ error, response }) => {
          if (error) {
            this.errorToast(error)
          } else {
            this.successToast('State updated successfully')
            this.$router.go(-1)
          }
        })
      } else {
        // Create new State
        this.api().states.create(this.input, ({ error, response }) => {
          if (error) {
            this.errorToast(error)
          } else {
            this.successToast('State created successfully')
            this.$router.go(-1)
          }
        })
      }
    },
    setupReportTypesForm (model) {
      if (this.input.code) delete this.input.code
      this.setupForm(model)
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
</style>
